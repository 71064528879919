import React, {useContext, useEffect, useReducer, useState} from 'react'
import {ModalContext} from "../../../../contexts"
import {Button, Col, Form, Input, Label, Row} from "reactstrap"
import {Tooltip} from "react-tooltip"
import {toast} from "react-toastify"
import {capitalize} from "lodash"
import {changeCryptoFee, changeFiatFee, getFee} from "../../../../api/fees"

function feesReducer(state, action) {
  switch (action.type) {
    case 'edit-item': {
      let newState = [...state]

      newState[action.payload.idx] = {
        ...newState[action.payload.idx],
        [action.payload.name]: action.payload.value,
      }

      return newState
    }
    case 'fill_fields': {
      return [...action.payload.data]
    }
    default: {
      return state
    }
  }
}

const feesDataInit = []

const FeesConfigModal = ({type, customerId, updateProfile}) => {
  const {closeModal} = useContext(ModalContext)
  const [filteredFees, setFilteredFees] = useState([])
  const [feesData, feesDataInitDispatch] = useReducer(feesReducer, feesDataInit)
  const [isFeesData, setIsFeesData] = useState(false)
  const [fees, setFees] = useState([])

  let feesMapper = []

  if (type === 'crypto') {
    feesMapper = [
      {title: "Low", name: "low", type: "crypto", fee: "", limit: "", time: "", is_disabled: false},
      {title: "Economy", name: "economy", type: "crypto", fee: "", limit: "", time: "", is_disabled: false},
      {title: "Normal", name: "normal", type: "crypto", fee: "", limit: "", time: "", is_disabled: false},
      {title: "High", name: "high", type: "crypto", fee: "", limit: "", time: "", is_disabled: false},
    ]
  } else {
    feesMapper = [
      {title: "Normal", name: "normal", type: "fiat", fee: "", time: "", is_disabled: false},
      {title: "High", name: "high", type: "fiat", fee: "", time: "", is_disabled: false},
    ]
  }

  const editFieldsHandler = (index, value, name) => {
    feesDataInitDispatch({
      type: 'edit-item', payload: {
        idx: index,
        value: value,
        name: name,
      }
    })
  }
  const getFeesData = () => {
    getFee(customerId).then(r => {
      setFees(r)
    })
  }

  const submitHandler = (e) => {
    e.preventDefault()

    let formData = {}

    for (const obj of feesData) {
      formData[obj.name] = obj

      if (obj['fee'] && Number(obj['fee']) < 0.001) {
        toast.info(`${obj['title']} Fee value must be greater than or equal to 0.001`)
        return
      }
    }

    if (type === 'crypto') {
      changeCryptoFee(formData, customerId).then(r => {
        if (r.success) {
          toast.success(r.message)
          closeModal()
          updateProfile()
        } else {
          toast.error(r.error)
        }
      })
    } else {
      changeFiatFee(formData, customerId).then(r => {
        if (r.success) {
          toast.success(r.message)
          closeModal()
          updateProfile()
        } else {
          toast.error(r.error)
        }
      })
    }
  }

  useEffect(() => {
    if (type) {
      setFilteredFees(feesMapper.filter(i => i.type === type))
    }
  }, [type])

  useEffect(() => {
    feesDataInitDispatch({
      type: 'fill_fields', payload: {
        data: filteredFees,
      }
    })
  }, [filteredFees])

  useEffect(() => {
    if (feesData && feesData?.length) {
      setIsFeesData(true)
    }
  }, [feesData])

  useEffect(() => {
    if (!fees[`${type}_fees`] || !isFeesData) return

    let newArr = []

    newArr = feesData.map(item => {
      let itemObjectKey = Object.keys(fees[`${type}_fees`]).find(key => key === item.name)
      let itemObject = fees[`${type}_fees`][itemObjectKey]

      if (item.name === itemObjectKey) {
        return (
          {
            title: capitalize(itemObjectKey),
            name: itemObjectKey,
            type: type,
            fee: itemObject.fee,
            limit: itemObject.limit,
            time: itemObject.time,
            is_disabled: itemObject.is_disabled
          }
        )
      } else {
        return item
      }
    })

    if (!newArr?.length) return

    feesDataInitDispatch({type: 'fill_fields', payload: {data: newArr}})
  }, [fees, isFeesData])

  useEffect(() => {
    getFeesData()
  }, []);

  return (
    <>
      <Form onSubmit={submitHandler}>
        {feesData?.length ?
          feesData.map((item, idx) => {
            return (
              <Row key={idx} className="mb-2">
                <Col>
                  <Label className="form-label mb-0 ms-2">Name</Label>
                  <Input
                    className="form-control"
                    type="text"
                    disabled
                    name={item.name}
                    defaultValue={item.title}
                  />
                  <div className="mt-1">
                    <Input
                      className="form-check-input"
                      name="is_disabled"
                      checked={item?.is_disabled}
                      onChange={e => {
                        editFieldsHandler(idx, e.target.checked, 'is_disabled')
                      }}
                      type="checkbox"
                    />
                    <Label className="form-check-label text-muted ms-2" for="hidden_from_customer">
                      Disabled
                    </Label>
                  </div>

                </Col>
                <Col className="col-2">
                  <Label className="form-label mb-0">Fee %</Label>
                  <Input
                    className="form-control"
                    type="text"
                    name="fee"
                    placeholder="Fee %"
                    autoComplete="false"
                    value={item?.fee || ''}
                    onChange={(e) => {
                      editFieldsHandler(idx, e.target.value, 'fee')
                    }}
                  />
                </Col>
                {item.type === 'crypto' ?
                  <Col>
                    <Label className="form-label mb-0">Limit in BTC</Label>
                    <Input
                      className="form-control"
                      type="text"
                      name="limit"
                      autoComplete="false"
                      placeholder="Amount in BTC"
                      value={item?.limit || ''}
                      onChange={(e) => {
                        editFieldsHandler(idx, e.target.value, 'limit')
                      }}
                    />
                    <div
                      className="position-absolute text-decoration-none text-muted m-0 p-0"
                      style={{top: '-3px', right: '18px'}}
                    >
                      <Button
                        color="link"
                        className="position-absolute text-decoration-none text-muted m-0 p-0"
                        type="button"
                        style={{cursor: 'help', top: '0px', right: '0px'}}
                        data-tooltip-content="To set to 'Unlimited' input '0'"
                        data-tooltip-id={idx + 1}
                      >
                        <i className="ri-information-line align-middle"></i>
                      </Button>
                      <Tooltip id={idx + 1}/>
                    </div>
                  </Col> : null
                }
                <Col>
                  <Label className="form-label mb-0">Estimated time</Label>
                  <Input
                    className="form-control"
                    type="text"
                    name="time"
                    autoComplete="false"
                    placeholder="Ex: 12 hours"
                    value={item?.time || ''}
                    onChange={(e) => {
                      editFieldsHandler(idx, e.target.value, 'time')
                    }}
                  />
                </Col>
              </Row>
            )
          })
          : null
        }

        <div className="pt-2 d-flex justify-content-between">
          <Button type="button" color="warning" className="btn" onClick={closeModal}>Close</Button>
          <Button type="submit" color="primary" className="btn">Save</Button>
        </div>
      </Form>
    </>
  )
}

export default FeesConfigModal
